/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import FormState from "gatsby-theme-firebase/src/containers/FormState"
import firebase from "gatsby-plugin-firebase"
import Form from "./FormBase"
import Button from "./Button"
import { trackCustomEvent } from "../../utils/analytics"
import { loadStripe } from "@stripe/stripe-js"
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js"
import ConsultingOTO, { OTOThanks } from "../../components/consultingOTO"

export const CreditCardForm = props => {
  return (
    <div>
      <label>Billing Info</label>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: "16px",
              color: "#424770",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
    </div>
  )
}
export const CheckoutForm = props => {
  const {
    setErrorMessage,
    loading,
    setLoading,
    submitPayment,
    update,
    pricingPlan,
    course,
  } = props
  const [termsChecked, checkTerms] = React.useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async event => {
    event.preventDefault()
    if (!termsChecked && !update) {
      return setErrorMessage("Please accept the terms")
    }
    if (course.cost === 0) {
      setErrorMessage(null)
      // Send the token to firebase.
      submitPayment()
    } else {
      const card = elements.getElement(CardElement)
      setLoading(true)
      const result = await stripe.createToken(card)
      if (result.error) {
        // Inform the user if there was an error.
        setLoading(false)
        setErrorMessage(result.error.message)
      } else {
        setErrorMessage(null)
        // Send the token to firebase.
        submitPayment(result.token)
      }
    }
  }
  return (
    <form onSubmit={handleSubmit}>
      {/* only show payment if not free course */}
      {pricingPlan === "once" && course.cost === 0 ? null : <CreditCardForm />}

      {update ? (
        <Button
          type="submit"
          disabled={!stripe || loading}
          style={{ width: "100%" }}
        >
          Update Card
        </Button>
      ) : (
        <div>
          <label className="inline-field">
            Accept{" "}
            <a target="_blank" href="/privacy-policy">
              Terms & Conditions
            </a>
            .
            <input
              type="checkbox"
              checked={termsChecked}
              onChange={() => {
                checkTerms(!termsChecked)
              }}
              name="terms"
              id="terms"
            />
            <span className="checkmark"></span>
          </label>

          <Button
            type="submit"
            disabled={!stripe || loading}
            style={{ width: "100%" }}
          >
            {course.shortName === "consulting" ? "Scedule a Call" : "Enroll"}{" "}
            for ${pricingPlan === "once" ? course.cost : "19/mo"}
          </Button>
        </div>
      )}
    </form>
  )
}

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC)

const SignUpForm: React.FunctionComponent<{
  onSuccess?: (user?: firebase.User | null) => void
  isLoggedIn?: boolean
  course?: any
}> = ({ onSuccess = () => {}, isLoggedIn, course, ...restProps }) => {
  const { setErrorMessage } = FormState.useContainer()
  const [displayName, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [isFlipped, setFlipped] = React.useState(0 || isLoggedIn ? 1 : 0)
  const [loading, setLoading] = React.useState(false)
  const [EVID, setEVID] = React.useState(null)
  const [pricingPlan, setPricingPlan] = React.useState(
    course && course.cost ? "once" : "monthly"
  )
  const [user, setUser] = React.useState({})

  React.useEffect(() => {
    //see if affiliate cookie exists
    if (typeof document !== "undefined") {
      var name = "EVID" + "="
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(";")
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) == " ") {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          console.log(c.substring(name.length, c.length))
          setEVID(c.substring(name.length, c.length))
        }
      }
    }
  }, [])
  const submitTwoStep = async () => {
    try {
      setErrorMessage(null)
      //check if valid email
      var mailformat = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      if (!email.toLowerCase().match(mailformat)) {
        return setErrorMessage("Please use a valid email.")
      }
      setLoading(true)
      await firebase.functions().httpsCallable("twoStep")({
        email,
        displayName,
      })
      setLoading(false)
      trackCustomEvent({
        // string - required - The object that was interacted with (e.g.video)
        category: "Monetization",
        // string - required - Type of interaction (e.g. 'play')
        action: "Became Lead",
      })
      setFlipped(1)
    } catch (error) {
      setLoading(false)
      setErrorMessage(error.message)
    }
  }
  const submitPayment = async token => {
    setErrorMessage(null)
    try {
      setLoading(true)
      const thisUser = await firebase.functions().httpsCallable("signUp")({
        email,
        password,
        displayName,
        token,
        pricingPlan,
        course,
        EVID,
      })
      trackCustomEvent({
        category: "Monetization",
        action:
          pricingPlan === "monthly"
            ? "Purchased Subscription"
            : `Purchased Course ${course.title}`,
        value: pricingPlan === "monthly" ? 19 : course.cost,
      })
      setUser(thisUser)
      if (!isLoggedIn)
        await firebase.auth().signInWithEmailAndPassword(email, password)
      setLoading(false)

      if (course.cost === 0) return onSuccess(user)

      if (course.shortName !== "consulting") {
        //ask for OTO
        setFlipped(2)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  const submitOTOPayment = async oto => {
    setErrorMessage(null)
    if (!oto || !oto.title) return onSuccess(user)
    try {
      setLoading(true)
      await firebase.functions().httpsCallable("purchaseOTO")({
        oto,
      })
      setLoading(false)
      trackCustomEvent({
        category: "Monetization",
        action: `Purchased OTO ${oto.title}`,
        value: oto.cost,
      })
      setFlipped(3)
    } catch (error) {
      console.log(error)
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  return (
    <div>
      {loading && (
        <div className="dark-overlay">
          <div className="spinner" />
        </div>
      )}
      {isFlipped === 0 && (
        <Form
          {...restProps}
          onSubmit={async event => {
            event.preventDefault()
            if (!isFlipped) {
              submitTwoStep()
            }
          }}
        >
          <div>
            <label>FIRST NAME:</label>
            <input
              type="text"
              required
              placeholder="Enter Your First Name..."
              value={displayName}
              onChange={event => {
                setName(event.target.value)
              }}
            />
            <label>EMAIL ADDRESS:</label>
            <input
              type="text"
              required
              placeholder="Enter Your Best Email Address..."
              value={email}
              onChange={event => {
                setEmail(event.target.value.replace(/\s+/g, ''))
              }}
            />
            <label>PASSWORD:</label>
            <input
              type="password"
              required
              value={password}
              placeholder="Enter Your New Password..."
              onChange={event => {
                setPassword(event.target.value)
              }}
            />
          </div>

          <Button
            disabled={loading}
            type="submit"
            style={{ width: "100%", marginTop: 10, fontSize: "1.5rem" }}
          >
            Next
          </Button>
        </Form>
      )}
      {isFlipped === 1 && (
        <div style={{ padding: "1.5rem" }} className="signup-form">
          {!isLoggedIn && (
            <Button
              disabled={loading}
              style={{ width: "20%", padding: 3, marginBottom: 10 }}
              onClick={() => setFlipped(0)}
            >
              ↶ Back
            </Button>
          )}

          <br />
          <div>
            {course.shortName === "consulting" ? "Schedule a " : "Enroll in "}
            <b>{course && course.title ? ` ${course.title}` : "Millionaire Millennial Academy"}</b> for:
          </div>
          <div className="row">
            {course && course.cost && (
              <div
                className={
                  pricingPlan === "once"
                    ? "column50 hover-zoom selected"
                    : "column50 hover-zoom"
                }
                onClick={() => setPricingPlan("once")}
              >
                <div style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                  ${course.cost}
                </div>
                {course.shortName === "consulting" ? (
                  <ul className="fancy-checks">
                    <li>
                      Grow rapidly by utilizing the knowledge & skillset of a
                      pro.
                    </li>
                    <li>
                      Take the confusion and guesswork out of building a
                      business.
                    </li>
                    <li>30 minutes long to ensure your success.</li>
                  </ul>
                ) : (
                  <ul className="fancy-checks">
                    <li>Only this course</li>
                    <li>One time payment</li>
                  </ul>
                )}
              </div>
            )}
            {course.shortName !== "consulting" && (
              <div
                className={
                  pricingPlan === "monthly"
                    ? "column50 hover-zoom selected"
                    : "column50 hover-zoom"
                }
                onClick={() => setPricingPlan("monthly")}
              >
                <div style={{ fontSize: "1.2rem", fontWeight: "bolder" }}>
                  $19/mo
                </div>
                <ul className="fancy-checks">
                  <li>Access to ALL courses and content on this site</li>
                  <li>Access to ALL mindset classes</li>
                  <li>Access to any and ALL live events</li>
                  <li>Cancel anytime</li>
                </ul>
              </div>
            )}
          </div>
          <Elements stripe={stripePromise}>
            <CheckoutForm
              pricingPlan={pricingPlan}
              course={course}
              displayName={displayName}
              email={email}
              setErrorMessage={setErrorMessage}
              loading={loading}
              setLoading={setLoading}
              submitPayment={submitPayment}
            />
          </Elements>
        </div>
      )}
      {isFlipped === 2 && (
        <ConsultingOTO
          acceptOTO={oto => submitOTOPayment(oto)}
          declineOTO={() => submitOTOPayment({})}
        />
      )}
      {isFlipped === 3 && <OTOThanks />}
    </div>
  )
}

export default SignUpForm
