/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import * as React from "react"
import useFirebaseConfig from "gatsby-theme-firebase/src/hooks/useFirebaseConfig"
import FormState from "gatsby-theme-firebase/src/containers/FormState"
import { auth } from "gatsby-theme-firebase/src/firebase"
import Form from "./FormBase"
import Button from "./Button"
import SocialLogins from "./SocialLogins"
import { useAuth } from "gatsby-theme-firebase"
import { navigate } from "gatsby" //import navigate from gatsby

const LoginForm: React.FunctionComponent<{
  onSuccess?: (user?: firebase.auth.UserCredential) => void
}> = ({ onSuccess = () => {}, ...restProps }) => {
  
  const { socialLogins } = useFirebaseConfig()
  const { setFormType, setErrorMessage } = FormState.useContainer()
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const { isLoggedIn } = useAuth()

  return (
    <Form
      {...restProps}
      onSubmit={async event => {
        event.preventDefault()
        try {
          const user = await auth.signInWithEmailAndPassword(email, password)
          onSuccess(user)
        } catch (error) {
          setErrorMessage(error.message)
        }
      }}
    >
      <label>Email Address</label>
      <input
        type="text"
        placeholder="email@example.com"
        value={email}
        onChange={event => {
          setEmail(event.target.value.replace(/\s+/g, ''))
        }}
      />

      <label>Password</label>
      <input
        type="password"
        value={password}
        onChange={event => {
          setPassword(event.target.value)
        }}
      />

      <Button type="submit" style={{marginTop:10,fontSize:'1.5rem'}}>
        Log in
      </Button>
      <Styled.hr />
      {socialLogins.length > 0 && (
        <div>
          <SocialLogins onSuccess={onSuccess} />
        </div>
      )}

      <Button
        style={{
          background: "transparent",
          color: "black",
          padding: 0,
        }}
        onClick={event => {
          event.preventDefault()
          setFormType("passwordReset")
        }}
      >
        Forgot your password?
      </Button>
    </Form>
  )
}

export default LoginForm
