/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import Button from "./Button"

const SocialLoginButton: React.FunctionComponent<React.ButtonHTMLAttributes<
  HTMLButtonElement
>> = ({ ...restProps }) => {
  return <button  className="social-login"
  type="button"
  {...restProps}
/>
}

export default SocialLoginButton
