/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";

const ErrorMessage: React.FunctionComponent<
  React.HTMLAttributes<HTMLDivElement>
> = ({ ...restProps }) => {
  return (
    <div className="error-message" {...restProps} />
  );
};

export default ErrorMessage;
