import * as React from "react"
import FormState from "gatsby-theme-firebase/src/containers/FormState"


const Nav: React.FunctionComponent<React.HTMLAttributes<HTMLDivElement>> = ({
  ...restProps
}) => {
  const { formType, setFormType } = FormState.useContainer()
  
  return (
    <div className="form-nav" style={{ display: "flex", width: "100%" }} {...restProps}>
      <button
        className={formType !== "signup" ? "nav-selected" : "nav-other"}
        onClick={() => {
          setFormType("login")
        }}
      >
        Sign Up
      </button>
      <button
        className={formType === "signup" ? "nav-selected" : "nav-other"}
        onClick={() => {
          setFormType("signup")
        }}
      >
        Log In
      </button>
    </div>
  )
}

export default Nav
