/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";

const Button: React.FunctionComponent<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ ...restProps }) => {
  return (
    <button
      type="button"
      {...restProps}
    />
  );
};

export default Button;
