import React from "react"

export const OTOThanks = () => {
  return (
    <div style={{ padding: "1.5rem" }} className="signup-form">
      <h3>Your purchase is complete!</h3>
      <small>
        Schedule your call below (or at the link that was emailed to you) If you
        have any issues, schedule with me directly:{" "}
        <a href="mailto:jordan@millionairemillennial.me">
          jordan@millionairemillennial.me
        </a>
      </small>
      <br />
      <br />
      <iframe
        frameBorder="none"
        className="calendly-inline-widget"
        src="https://calendly.com/jordankilburn/30m-consulting-call"
        style={{
          margin: "auto",
          width: "100%",
          height: "1000px",
        }}
      />
    </div>
  )
}
export default props => {
  const { acceptOTO, declineOTO } = props

  const cost = 75
  const title = "30m Consulting w/ Jordan"

  return (
    <div style={{ padding: "1.5rem" }} className="signup-form">
      <div>
        <small>Before you finish your order...</small>
        <h3>
          Would you like to have a{" "}
          <span style={{ color: "#3273dc" }}>consulting call</span> with Jordan
          at an <i>extreme</i> discount?
        </h3>
      </div>
      <div className="row">
        <div className="column50">
          <ul className="fancy-checks">
            <li>
              Grow rapidly by utilizing the knowledge & skillset of a pro.
            </li>
            <li>
              Take the confusion and guesswork out of building a business.
            </li>
            <li>Due to high demand, this usually goes for $200/hr+</li>
            <li>
              This is the only time you can schedule a call at this low price!
            </li>
          </ul>
        </div>
      </div>
      <button
        onClick={() => acceptOTO({ title, cost })}
        type="submit"
        className="cta-button"
        style={{ fontSize: "1.2rem", marginTop: 10 }}
      >
        Yes! I want to have a 30m call for only ${cost}!
      </button>
      <div style={{ textAlign: "center" }}>
        <button
          onClick={() => declineOTO()}
          type="submit"
          className="btn-danger"
          style={{
            padding: 4,
            width: "auto",
            marginTop: 20,
          }}
        >
          No - I do not want this great deal.
        </button>
      </div>
    </div>
  )
}
