/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import useSiteMetadata from "gatsby-theme-firebase/src/hooks/useSiteMetadata"
import FormState from "gatsby-theme-firebase/src/containers/FormState"
import LoginForm from "./LoginForm"
import SignUpForm from "./SignUpForm"
import PasswordResetForm from "./PasswordResetForm"
import Nav from "./Nav"
import ErrorMessage from "./ErrorMessage"

const Form: React.FunctionComponent<{
  onSignUpSuccess?: (user?: firebase.User | null) => void
  onLoginSuccess?: (user?: firebase.auth.UserCredential) => void
  onResetSuccess?: () => void
  isLoggedIn?: boolean
  course?: object
  setToggleLogin?: (arg0: boolean) => void
}> = ({
  onLoginSuccess,
  onSignUpSuccess,
  onResetSuccess,
  isLoggedIn,
  course,
  setToggleLogin,
}) => {
  const { formType, errorMessage } = FormState.useContainer()

  return (
    <div className="login-form">
      {!isLoggedIn && <Nav />}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {setToggleLogin &&<div
        className="modal-close"
        onClick={() => {
          setToggleLogin(false)
        }}
      >
        X
      </div>}
      {formType === "login" && (
        <SignUpForm
          onSuccess={onSignUpSuccess}
          isLoggedIn={isLoggedIn}
          course={course}
        />
      )}
      {formType === "signup" && <LoginForm onSuccess={onLoginSuccess} />}
      {formType === "passwordReset" && (
        <PasswordResetForm onSuccess={onResetSuccess} />
      )}
    </div>
  )
}

export default Form
