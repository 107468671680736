/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import useFirebaseConfig from "gatsby-theme-firebase/src/hooks/useFirebaseConfig";
import { auth } from "gatsby-theme-firebase/src/firebase";
import {
  googleProvider,
  githubProvider,
  twitterProvider,
  facebookProvider,
} from "gatsby-theme-firebase/src/firebase/auth";
import SocialLoginButton from "./SocialLoginButton";
import GitHubIcon from "gatsby-theme-firebase/src/components/icons/GitHub";
import GoogleIcon from "gatsby-theme-firebase/src/components/icons/Google";
import TwitterIcon from "gatsby-theme-firebase/src/components/icons/Twitter";
import FacebookIcon from "gatsby-theme-firebase/src/components/icons/Facebook";

const SocialLogins: React.FunctionComponent<{
  onSuccess?: (user?: firebase.auth.UserCredential) => void;
  onError?: (err: any) => void;
}> = ({ onSuccess = () => {}, onError = () => {}, ...restProps }) => {
  const { socialLogins } = useFirebaseConfig();
  const enableGoogle = socialLogins.includes("google");
  const enableTwitter = socialLogins.includes("twitter");
  const enableGitHub = socialLogins.includes("github");
  const enableFacebook = socialLogins.includes("facebook");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
      {...restProps}
    >
      {enableGoogle && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(googleProvider());
              onSuccess(user);
            } catch (err) {
              console.error("Authentication Error: ", err);
              onError(err);
            }
          }}
        >
          <GoogleIcon size={18} />&nbsp;Log in with Google
        </SocialLoginButton>
      )}
      {enableTwitter && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(twitterProvider());
              onSuccess(user);
            } catch (err) {
              console.error("Authentication Error: ", err);
              onError(err);
            }
          }}
        >
          <TwitterIcon  size={18} />&nbsp;Log in with Twitter
        </SocialLoginButton>
      )}
      {enableFacebook && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(facebookProvider());
              onSuccess(user);
            } catch (err) {
              console.error("Authentication Error: ", err);
              onError(err);
            }
          }}
        >
          <FacebookIcon  size={18} />&nbsp;Log in with Facebook
        </SocialLoginButton>
      )}
      {enableGitHub && (
        <SocialLoginButton
          onClick={async () => {
            try {
              const user = await auth.signInWithPopup(githubProvider());
              onSuccess(user);
            } catch (err) {
              console.error("Authentication Error: ", err);
              onError(err);
            }
          }}
        >
          <GitHubIcon  size={18} />&nbsp;Log in with Github
        </SocialLoginButton>
      )}
    </div>
  );
};

export default SocialLogins;
